import { setDisplayAssetGroupEditor, setDisplayTableDefinitionEditor } from '@/worksheet/worksheet.actions';
import { IJsonBorderNode, IJsonModel } from 'flexlayout-react';
import {
  setAssetGroupEditorVisible,
  setCalculationHierarchyInTab,
  setTableDefinitionEditorVisible,
} from '@/main/layout.actions';
import { reset } from '@/assetGroupEditor/assetGroup.actions';
import { resetTableDefinition } from '@/tableDefinitionEditor/tableDefinition.actions';
import { toggleHelp } from '@/tools/formula/formulaTool.actions';

export const searchTabId = 'search';
export const investigateTabId = 'investigate';
export const journalTabId = 'annotate';
export const worksheetsTabId = 'worksheetsTab';
export const dataPanelTabId = 'search';
export const trendTabId = 'trendTabId';
export const trendTabSetId = 'trendTabSetId';
export const detailsPanelTabId = 'detailsPanel';
export const toolsPanelTabId = 'investigate';
export const journalPanelTabId = 'annotate';
export const capsulePanelTabId = 'capsulePanelTabId';
export const formulaHelpTabId = 'formulaHelo';
export const propertiesTabId = 'reportConfig';
export const commentsTabId = 'reportComments';
export const editorTabId = 'editorTabId';
export const tableDefinitionTabId = 'tableDefinitionTabId';
export const assetGroupTabId = 'assetGroupTabId';
export const calculationHierarchyTabId = 'calculationHierarchyTabId';
export const collapsibleSidePanelId = 'collapsibleSidePanelId';
// TO DO: Remove when implementing CRAB-43396
export const vantageTabId = 'vantageTabId';
// export const evidenceTableTabId = 'evidenceTable';
// export const contextPanelTabId = 'contextPanel';
export const SIDE_PANEL_INITIAL_WIDTH = 375;

const globalConfig = {
  tabSetEnableSingleTabStretch: false,
  tabSetEnableDeleteWhenEmpty: true,
  tabSetMinWidth: 30,
  tabSetMinHeight: 100,
  splitterSize: 3,
  splitterExtra: 0,
  tabEnableClose: false,
  tabEnableRename: false,
};

const trendTabJson = {
  type: 'tab',
  id: trendTabId,
  name: 'LAYOUT.TREND_TAB',
  component: trendTabId,
  config: {
    icon: 'fc-trend',
  },
};

const journalTabJson = {
  type: 'tab',
  id: journalPanelTabId,
  name: 'LAYOUT.JOURNAL_TAB',
  component: journalPanelTabId,
  config: {
    icon: 'fa-book fa-regular',
    testId: journalTabId,
    clickAction: 'TABSET_CHANGE',
  },
};

const organizerConfigTabJson = {
  type: 'tab',
  id: propertiesTabId,
  name: 'LAYOUT.PROPERTIES_TAB',
  component: propertiesTabId,
  config: {
    icon: 'fa-cog fa-regular',
    testId: propertiesTabId,
    clickAction: 'TABSET_CHANGE',
  },
};

const organizerCommentsTabJson = {
  type: 'tab',
  id: commentsTabId,
  name: 'LAYOUT.COMMENTS_TAB',
  component: commentsTabId,
  config: {
    icon: 'fa-comment-dots fa-regular',
    testId: commentsTabId,
    clickAction: 'TABSET_CHANGE',
  },
};

const editorTabJson = {
  type: 'tab',
  id: editorTabId,
  name: 'LAYOUT.EDITOR_TAB',
  component: editorTabId,
  config: {
    icon: 'fa-pencil fa-regular',
  },
};

const dataPanelTabJson = {
  type: 'tab',
  id: dataPanelTabId,
  name: 'LAYOUT.DATA_TAB',
  component: dataPanelTabId,
  config: {
    icon: 'fa-database fa-regular',
    testId: searchTabId,
    clickAction: 'TABSET_CHANGE',
  },
};

const toolsPanelTabJson = {
  type: 'tab',
  id: toolsPanelTabId,
  name: 'LAYOUT.TOOLS_TAB',
  component: toolsPanelTabId,
  config: {
    icon: 'fa-wrench fa-regular',
    testId: investigateTabId,
    clickAction: 'TABSET_CHANGE',
  },
};

export const detailsPanelTabJson = {
  type: 'tab',
  id: detailsPanelTabId,
  name: 'LAYOUT.DETAILS_TAB',
  component: detailsPanelTabId,
  config: {
    icon: 'fa-chart-area',
  },
};

const worksheetsTabJson = (report: boolean) => ({
  type: 'tab',
  id: worksheetsTabId,
  name: report ? 'LAYOUT.DOCUMENTS_TAB' : 'LAYOUT.WORKSHEETS_TAB',
  component: worksheetsTabId,
  config: {
    icon: 'fa-files',
    testId: 'worksheetsTab',
    clickAction: 'TOGGLE_WORKSHEET',
  },
});

export const assetGroupTabJson = {
  type: 'tab',
  id: assetGroupTabId,
  name: 'LAYOUT.ASSET_GROUP_EDITOR_TAB',
  component: assetGroupTabId,
  config: {
    icon: 'fa-cubes',
    closeAction: 'CLOSE_ASSET_GROUP',
  },
};

export const tableDefinitionTabJson = {
  type: 'tab',
  id: tableDefinitionTabId,
  name: 'LAYOUT.TABLE_DEFINITION_TAB',
  component: tableDefinitionTabId,
  config: {
    icon: 'fa-table',
    closeAction: 'CLOSE_TABLE_DEFINITION_EDITOR',
  },
};

const bordersJSON = (report: boolean): IJsonBorderNode[] => [
  {
    type: 'border',
    selected: 0,
    location: 'left',
    minSize: 30,
    size: 122,
    children: [worksheetsTabJson(report)],
  },
];

// TO DO: Remove when implementing CRAB-43396
const vantageTabJson = {
  type: 'tab',
  id: vantageTabId,
  name: 'Vantage',
  component: vantageTabId,
  config: {
    testId: vantageTabId,
  },
};
/*
const evidenceTableTabJson = {
  type: 'tab',
  id: evidenceTableTabId,
  name: 'Evidence Table',
  component: evidenceTableTabId,
  config: {
    icon: 'fa-table fa-regular',
    testId: evidenceTableTabId,
  },
};
const contextPanelTabJson = {
  type: 'tab',
  id: contextPanelTabId,
  name: 'Context Panel',
  component: contextPanelTabId,
  config: {
    icon: 'fa-book fa-regular',
    testId: contextPanelTabId,
  },
};
 */

export const organizerLayoutJson: IJsonModel = {
  global: globalConfig,
  borders: bordersJSON(true),
  layout: {
    type: 'row',
    id: 'root',
    children: [
      {
        type: 'tabset',
        id: collapsibleSidePanelId,
        width: SIDE_PANEL_INITIAL_WIDTH,
        children: [organizerConfigTabJson, organizerCommentsTabJson],
      },
      {
        type: 'tabset',
        id: 'editorTabSetId',
        children: [editorTabJson],
      },
    ],
  },
};
export const worksheetLayoutJSON: IJsonModel = {
  global: globalConfig,
  borders: bordersJSON(false),
  layout: {
    type: 'row',
    id: 'root',
    children: [
      {
        type: 'tabset',
        id: collapsibleSidePanelId,
        width: 375,
        children: [dataPanelTabJson, toolsPanelTabJson, journalTabJson],
      },
      {
        type: 'row',
        children: [
          {
            type: 'tabset',
            id: trendTabSetId,
            weight: 130,
            children: [trendTabJson],
          },
          {
            type: 'tabset',
            weight: 50,
            children: [detailsPanelTabJson],
          },
        ],
      },
    ],
  },
};
// TO DO: Remove when implementing CRAB-43396
export const tempVantageLayoutJson: IJsonModel = {
  global: globalConfig,
  borders: [],
  layout: {
    type: 'row',
    id: 'root',
    weight: 100,
    children: [
      {
        enableTabStrip: false,
        tabStripHeight: 0,
        type: 'tabset',
        weight: 100,
        enableClose: false,
        enableDrop: false,
        children: [vantageTabJson],
      },
    ],
  },
};

/*
export const vantageLayoutJson: IJsonModel = {
  global: {
    tabSetEnableSingleTabStretch: false,
    tabSetEnableDeleteWhenEmpty: true,
    tabSetMinWidth: 30,
    tabSetMinHeight: 100,
    splitterSize: 3,
    splitterExtra: 0,
    tabEnableClose: false,
    tabEnableRename: false,
  },
  borders: [],
  layout: {
    type: 'row',
    id: 'root',
    weight: 100,
    children: [
      {
        type: 'tabset',
        weight: 70,
        enableClose: false,
        enableDrop: false,
        children: [evidenceTableTabJson],
      },
      {
        type: 'tabset',
        weight: 30,
        enableClose: false,
        enableDrop: false,
        children: [contextPanelTabJson],
      },
    ],
  },
};
 */

export const mobileOrganizerLayoutJSON: IJsonModel = {
  global: globalConfig,
  borders: [
    {
      type: 'border',
      selected: -1,
      location: 'left',
      size: 122,
      children: [worksheetsTabJson(true), organizerConfigTabJson, organizerCommentsTabJson],
    },
  ],
  layout: {
    type: 'row',
    id: 'root',
    children: [
      {
        type: 'tabset',
        id: 'editorTabSetId',
        children: [editorTabJson],
      },
    ],
  },
};
export const mobileWorksheetLayoutJSON: IJsonModel = {
  global: globalConfig,
  borders: [
    {
      type: 'border',
      selected: -1,
      location: 'left',
      size: 122,
      children: [worksheetsTabJson(false), dataPanelTabJson, toolsPanelTabJson, journalTabJson],
    },
  ],
  layout: {
    type: 'row',
    id: 'root',
    children: [
      {
        type: 'row',
        children: [
          {
            type: 'tabset',
            id: trendTabSetId,
            weight: 130,
            children: [trendTabJson],
          },
          {
            type: 'tabset',
            weight: 50,
            children: [detailsPanelTabJson],
          },
        ],
      },
    ],
  },
};
export const capsulePanelTabJson = {
  type: 'tab',
  id: capsulePanelTabId,
  name: 'LAYOUT.CAPSULES_TAB',
  component: capsulePanelTabId,
  config: {
    icon: 'fc-capsule',
  },
};

export const formulaHelpTabConfig = {
  icon: 'fa-info-circle',
  customPosition: false,
  closeAction: 'CLOSE_FORMULA_HELP',
};
export const formulaHelpTabJson = {
  type: 'tab',
  id: formulaHelpTabId,
  name: 'LAYOUT.FORMULA_HELP_TAB',
  component: formulaHelpTabId,
  config: formulaHelpTabConfig,
};

export const calculationHierarchyTabJson = {
  type: 'tab',
  id: calculationHierarchyTabId,
  name: 'LAYOUT.CALCULATION_HIERARCHY_TAB',
  component: calculationHierarchyTabId,
  config: {
    icon: 'fc-asset-tree',
    closeAction: 'CLOSE_CALCULATION_HIERARCHY',
  },
};

export const closeActionLookup: Record<string, () => void> = {
  CLOSE_ASSET_GROUP: () => {
    setDisplayAssetGroupEditor(false);
    reset();
    setAssetGroupEditorVisible(false);
  },
  CLOSE_TABLE_DEFINITION_EDITOR: () => {
    setDisplayTableDefinitionEditor(false);
    resetTableDefinition();
    setTableDefinitionEditorVisible(false);
  },
  CLOSE_FORMULA_HELP: () => {
    toggleHelp();
  },
  CLOSE_CALCULATION_HIERARCHY: () => {
    setCalculationHierarchyInTab(false);
  },
};
