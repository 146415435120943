import { flux } from '@/core/flux.module';
import { TableDefinitionOutputV1 } from '@/sdk';
import {
  MaterializedTableItemColumnPropertyPair,
  MaterializedTableOutput,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { BatchActionEnum } from 'sdk/model/TableDefinitionInputV1';

export function setSubscriptionId(id: string | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_SUBSCRIBER_ID', { subscriberId: id });
}

export function setShouldSearchForItems(shouldSearchForItems: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_SHOULD_SEARCH_FOR_ITEMS', { shouldSearchForItems });
}

export function setName(name: string) {
  flux.dispatch('TABLE_DEFINITION_SET_NAME', { name });
}

export function setDescription(description: string) {
  flux.dispatch('TABLE_DEFINITION_SET_DESCRIPTION', { description });
}

export function setBatchAction(batchAction: BatchActionEnum) {
  flux.dispatch('TABLE_DEFINITION_SET_BATCH_ACTION', { batchAction });
}

export function setTableDefinition(output: TableDefinitionOutputV1) {
  flux.dispatch('TABLE_DEFINITION_SET_TABLE_DEFINITION', { tableDefinition: output });
}

export function resetTableDefinition() {
  flux.dispatch('TABLE_DEFINITION_RESET');
}

export function setMaterializedTable(materializedTable: MaterializedTableOutput | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_MATERIALIZED_TABLE', { materializedTable });
}

export function setDoTableReload(doTableReload: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_DO_TABLE_RELOAD', { doTableReload });
}

export function setDisplayPropertyForColumn(uuidColumnToPropertyNamePair: MaterializedTableItemColumnPropertyPair) {
  flux.dispatch('TABLE_DEFINITION_SET_PROPERTY_NAME_FOR_UUID_COLUMN', { uuidColumnToPropertyNamePair });
}

export function addPendingTableId(pendingTableId: string) {
  flux.dispatch('TABLE_DEFINITION_ADD_PENDING_TABLE_ID', { pendingTableId });
}

export function removePendingTableId(pendingTableId: string) {
  flux.dispatch('TABLE_DEFINITION_REMOVE_PENDING_TABLE_ID', { pendingTableId });
}
