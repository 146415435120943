import _ from 'lodash';
import { ColumnRuleOutputV1 } from 'sdk/model/ColumnRuleOutputV1';
import {
  ColumnDefinitionOutputV1,
  ColumnRuleAssetCreatorInputV1,
  ColumnRuleFormulaCreatorInputV1,
  ColumnRuleGetItemPropertyInputV1,
  ColumnRuleInputV1,
} from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AllColumnEnumOptions, ColumnRule } from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { ColumnRulesWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.constants';
import { getColumnRuleWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.utilities';
import {
  ColumnRuleWithMetadata,
  PotentialColumnDefinition,
} from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import {
  ScalingTableColumnDefinition,
  TableDefinitionAccessSettings,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { getMaterializedTable } from '@/tableDefinitionEditor/tableDefinition.utilities';

export const getAllowedColumnTypes = (columnRule: ColumnRule): ColumnTypeEnum[] => {
  return getColumnRuleWithMetaData(columnRule)?.allowedOnColumnTypes ?? [];
};

export const getRulesAllowedOnColumnType = (columnType: ColumnTypeEnum): ColumnRuleWithMetadata[] => {
  return ColumnRulesWithMetaData.filter((columnRule) => columnRule.allowedOnColumnTypes.includes(columnType));
};

export const getAllowedColumnTypesGivenCurrentColumnAndSetOfRules = (
  ruleInputs: ColumnRuleInputV1[],
  currentColumnTypeIfConfigured?: ColumnTypeEnum,
): ColumnTypeEnum[] => {
  return ruleInputs.reduce((commonTypes, ruleInput) => {
    const rule: ColumnRule = getRuleTypeFromRuleInput(ruleInput);
    let currentList: ColumnTypeEnum[];
    if (
      rule === SeeqNames.MaterializedTables.Rules.Constant.BaseConstant &&
      currentColumnTypeIfConfigured !== undefined
    ) {
      // A constant rule that has been configured on a given column is by necessity of the same type as the
      // column, so we don't want to allow changing the column type if you have a configured constant rule
      currentList = [currentColumnTypeIfConfigured];
    } else {
      currentList = getAllowedColumnTypes(rule);
    }
    return _.compact(commonTypes.filter((type) => currentList.includes(type)));
  }, AllColumnEnumOptions);
};

export const getRuleTypeFromRuleInput = (ruleInput: ColumnRuleInputV1): ColumnRule => {
  // Each rule input should have only one field filled out, so we can just get the first one as the rule type
  return Object.keys(ruleInput)[0] as ColumnRule;
};

export const columnRuleOutputToColumnRuleInput = (
  ruleOutput: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
  accessSettings: TableDefinitionAccessSettings,
): ColumnRuleInputV1 | any => {
  // The constant rule is unique in that there is only 1 api input type but multiple api output types
  const isConstantRule = /constant/i.test(ruleOutput.rule);
  const ruleType = isConstantRule ? SeeqNames.MaterializedTables.Rules.Constant.BaseConstant : ruleOutput.rule;
  if (!isValidColumnRule(ruleType)) {
    throw new Error(`Invalid rule type: ${ruleType}`);
  }

  const columnRuleWithMetadata = getColumnRuleWithMetaData(ruleType);
  if (!columnRuleWithMetadata) {
    throw new Error(`Could not find metadata for rule: ${ruleType}`);
  }

  const result = {
    [ruleType]: columnRuleWithMetadata.columnRuleOutputToColumnRuleInput(ruleOutput, otherColumns, accessSettings),
  };

  if (ruleType === 'formulaCreator') {
    return { ...result, inputs: ruleOutput.inputs };
  }

  return result;
};

const isValidColumnRule = (rule: string): rule is ColumnRule => {
  return ColumnRulesWithMetaData.some((columnRule) => columnRule.rule === rule);
};

export const getPotentialColumnsForTreePathRule = async (
  columnDefinitionsToCreate: PotentialColumnDefinition[],
  existingColumnDefinitions: ScalingTableColumnDefinition[],
  tableDefinitionId: string,
  potentialRootColumnPairIfNotAlreadySelectdForCreation?: {
    rootText: PotentialColumnDefinition;
    rootAsset: PotentialColumnDefinition;
  },
  scopedTo?: string,
): Promise<PotentialColumnDefinition[]> => {
  const allowedExistingColumnDefinitionsWithColumnIndex = existingColumnDefinitions
    .map((column, index) => ({
      ...column,
      columnIndex: index + 1,
    }))
    .filter((column) => column.columnName !== SeeqNames.MaterializedTables.DatumIdColumn);

  const UUIDColumns = allowedExistingColumnDefinitionsWithColumnIndex
    .filter((column) => column.columnType === ColumnTypeEnum.UUID)
    .map((column, index) => {
      const isMadeWithCreatorRule = column.rules.some((rule) => rule.rule.includes('Creator'));
      const hasOnlyOneRule = column.rules.length === 1;
      return { ...column, isMadeWithCreatorRule: isMadeWithCreatorRule && hasOnlyOneRule };
      //todo: deal with whether there are overrides or not
    });
  console.log('UUIDColumns', UUIDColumns);

  const textColumnsNotAlreadyUsedAsAssetInputs = allowedExistingColumnDefinitionsWithColumnIndex
    .filter((column) => column.columnType === ColumnTypeEnum.TEXT)
    .filter(
      (column) =>
        !allowedExistingColumnDefinitionsWithColumnIndex.some(
          (col) => col.rules[0]?.rule === 'assetCreator' && col.rules[0]?.inputs?.[0] === column.id,
        ),
    );

  const startingIndex = existingColumnDefinitions.length + columnDefinitionsToCreate.length + 1;
  const alreadyChosenPotentialColumns = columnDefinitionsToCreate.filter((col) => col.isOptionForDropdown);
  const potentialAssetColumnsBasedOnTextColumns = textColumnsNotAlreadyUsedAsAssetInputs
    .filter(
      (textColumn) =>
        !columnDefinitionsToCreate.some(
          (col) => col.columnRules[0]?.assetCreator?.columnIndex === textColumn.columnIndex,
        ),
    )
    .map((col, index) => ({
      columnName: `${col.columnName} Asset`,
      columnType: ColumnTypeEnum.UUID,
      columnIndex: startingIndex + index + 1,
      isOptionForDropdown: true,
      sourceColumnDisplayName: col.displayName,
      dropdownDescription: 'Column of Assets generated from Text Column of the same name',
      columnRules: [
        {
          [SeeqNames.MaterializedTables.Rules.AssetCreator]: {
            columnIndex: col.columnIndex,
            isRoot: false,
            scopedTo,
            description: `Asset created from ${col.columnName}`,
          } as ColumnRuleAssetCreatorInputV1,
        },
      ],
    }))
    .filter((col) => !alreadyChosenPotentialColumns.some((chosenCol) => chosenCol.columnName === col.columnName));
  console.log('potentialAssetColumnsBasedOnTextColumns', potentialAssetColumnsBasedOnTextColumns);

  const uuidColumnsNotCreatedInTable = UUIDColumns.filter((col) => !col.isMadeWithCreatorRule);
  const uuidColumnCreatedInTable = UUIDColumns.filter((col) => col.isMadeWithCreatorRule);
  const uuidColumnsWithAssetOrFormulaTag = await Promise.all(
    uuidColumnsNotCreatedInTable.map(async (col) => ({
      ...col,
      isAssetColumn: await columnContainsOnlyAssets(tableDefinitionId, col),
      isFormulaColumn: await columnValuesCanBeUsedForPassThruCreation(tableDefinitionId, col),
    })),
  );
  console.log('uuidColummnsCreatedInTable', uuidColumnCreatedInTable);
  console.log('uuidColumnsNotCreatedInTable', uuidColumnsNotCreatedInTable);
  // const uuidColumnsToPotentialCreateFormulaPassthroughItems = uuidColumnsWithAssetOrFormulaTag.filter(
  //   (col) => col.isFormulaColumn,
  // );
  // const potentialFormPassThruColumnsDeDupedFromExisitngPotetnialColumns =
  //   uuidColumnsToPotentialCreateFormulaPassthroughItems.filter(
  //     (col) =>
  //       !columnDefinitionsToCreate.some((colDef) =>
  //         colDef.columnRules[0]?.formulaCreator?.columnIndexes.includes(col.columnIndex),
  //       ),
  //   );
  //
  // const potentialColumnsThatDoNotAlreadyHaveAPassThru =
  //   potentialFormPassThruColumnsDeDupedFromExisitngPotetnialColumns.filter((uuidColumn) => {
  //     const columnWithFormulaThatUsesThisColumn = uuidColumnCreatedInTable.find(
  //       (colDef) =>
  //         colDef.rules[0]?.rule === SeeqNames.MaterializedTables.Rules.FormulaCreator &&
  //         colDef.rules[0]?.inputs?.includes(uuidColumn.id),
  //     );
  //     console.log('columnWithFormulaThatUsesThisColumn', columnWithFormulaThatUsesThisColumn);
  //     return !columnWithFormulaThatUsesThisColumn;
  //   });
  // console.log('potentialColumnsThatDoNotAlreadyHaveAPassThru', potentialColumnsThatDoNotAlreadyHaveAPassThru);

  const potentialFormulaPassThroughItems = uuidColumnsWithAssetOrFormulaTag
    .filter((col) => col.isFormulaColumn)
    .filter(
      (uuidColumn) =>
        !columnDefinitionsToCreate.some((colDef) =>
          colDef.columnRules[0]?.formulaCreator?.columnIndexes.includes(uuidColumn.columnIndex),
        ),
    )
    // .filter((uuidColumn) => !uuidColumnCreatedInTable.some((col) => col.rules[0]?.inputs?.includes(uuidColumn.id)))
    .map((col, index) => {
      const columnName = `${col.columnName} Pass Thru Item`;
      return {
        columnName,
        columnType: ColumnTypeEnum.UUID,
        columnIndex: startingIndex + potentialAssetColumnsBasedOnTextColumns.length + index + 1,
        sourceColumnDisplayName: col.displayName,
        dropdownDescription: 'Column of Jump Tags based on existing UUID column by this name',
        isOptionForDropdown: true,
        columnRules: [
          {
            [SeeqNames.MaterializedTables.Rules.FormulaCreator]: {
              columnIndexes: [col.columnIndex],
              scopedTo,
              formula: '$a',
              parameters: ['a'],
              name: col.columnName,
              description: `Formula item created from ${col.columnName} as a passthrough`,
            } as ColumnRuleFormulaCreatorInputV1,
          },
        ],
      };
    })
    .filter((col) => !alreadyChosenPotentialColumns.some((chosenCol) => chosenCol.columnName === col.columnName));

  const uuidColumnsWithOnlyAssetsNotCreatedInTable = uuidColumnsWithAssetOrFormulaTag.filter(
    (col) => col.isAssetColumn && !col.isMadeWithCreatorRule,
  );

  const textColumnsBasedOnExistingAssetColumns = uuidColumnsWithOnlyAssetsNotCreatedInTable
    .filter((col) => {
      return !columnDefinitionsToCreate.some(
        (colDef) => colDef.columnRules?.[0]?.getItemProperty?.columnIndex === col.columnIndex,
      );
      // &&
      // !allowedExistingColumnDefinitionsWithColumnIndex.some(
      //   (colDef) =>
      //     colDef.rules?.[0]?.rule === SeeqNames.MaterializedTables.Rules.GetItemProperty &&
      //     colDef.rules[0].inputs?.[0] === col.id,
      // )
      // );
      // .some(
      // (colDef) =>
      //   colDef.rules[0].rule === SeeqNames.MaterializedTables.Rules.AssetCreator &&
      //   colDef.rules[0].inputs?.[0] === col.id,
      // );
    })
    .map((col, index) => ({
      columnName: `${col.displayName} Text`,
      columnType: ColumnTypeEnum.TEXT,
      columnIndex:
        startingIndex +
        potentialAssetColumnsBasedOnTextColumns.length +
        potentialFormulaPassThroughItems.length +
        index +
        1,
      isOptionForDropdown: false,
      sourceColumnDisplayName: col.displayName,
      columnRules: [
        {
          [SeeqNames.MaterializedTables.Rules.GetItemProperty]: {
            propertyName: 'name',
            columnIndex: col.columnIndex,
          } as ColumnRuleGetItemPropertyInputV1,
        },
      ],
    }));
  console.log('textColumnsBasedOnExistingAssetColumns', textColumnsBasedOnExistingAssetColumns);

  const assetColumnsBasedOnExistingAssetColumns = textColumnsBasedOnExistingAssetColumns
    .filter(
      (col) =>
        !columnDefinitionsToCreate.some(
          (colDef) => colDef.columnRules?.[0]?.assetCreator?.columnIndex === col.columnIndex,
        ),
    )
    .map((col, index) => ({
      columnName: `${col.sourceColumnDisplayName} Copy`,
      columnType: ColumnTypeEnum.UUID,
      columnIndex:
        startingIndex +
        potentialAssetColumnsBasedOnTextColumns.length +
        potentialFormulaPassThroughItems.length +
        textColumnsBasedOnExistingAssetColumns.length +
        index +
        1,
      isOptionForDropdown: true,
      dropdownDescription: 'Column of Jump Tags representing column of existing Assets by the same name.',
      sourceColumnDisplayName: col.sourceColumnDisplayName,
      sourceColumnDisplayNameIfSourceIsNotYetCreated: col.columnName,
      columnRules: [
        {
          [SeeqNames.MaterializedTables.Rules.AssetCreator]: {
            columnIndex: col.columnIndex,
            isRoot: false,
            scopedTo,
            description: `Asset created from ${col.sourceColumnDisplayName}`,
          } as ColumnRuleAssetCreatorInputV1,
        },
      ],
    }));
  console.log('assetColumnsBasedOnExistingAssetColumns', assetColumnsBasedOnExistingAssetColumns);

  const potentialRootColumnNeeded = potentialRootColumnPairIfNotAlreadySelectdForCreation
    ? potentialRootColumnPairIfNotAlreadySelectdForCreation.rootAsset
    : undefined;

  const potentialAdditionalColumns: PotentialColumnDefinition[] = alreadyChosenPotentialColumns.concat(
    potentialAssetColumnsBasedOnTextColumns,
    potentialFormulaPassThroughItems,
    textColumnsBasedOnExistingAssetColumns,
    assetColumnsBasedOnExistingAssetColumns,
  );
  console.log('potentialAdditionalColumns', potentialAdditionalColumns);
  const totalColumnsSoFar = potentialAdditionalColumns.length + existingColumnDefinitions.length;
  return potentialRootColumnNeeded
    ? potentialAdditionalColumns.concat(
        {
          ...potentialRootColumnPairIfNotAlreadySelectdForCreation!.rootText,
          columnIndex: totalColumnsSoFar + 1,
        },
        {
          ...potentialRootColumnNeeded,
          columnIndex: totalColumnsSoFar + 2,
        },
      )
    : potentialAdditionalColumns;
};

const columnContainsOnlyAssets = async (
  tableDefinitionId: string,
  column: ScalingTableColumnDefinition,
): Promise<boolean> => {
  if (column.columnType !== ColumnTypeEnum.UUID) {
    return false;
  }
  const columnWithTypeProperty = await getMaterializedTable(
    tableDefinitionId,
    [column.columnName],
    [{ uuidColumn: column.columnName, propertyNames: ['type'] }],
  );
  // console.log('columnWithTypeProperty', columnWithTypeProperty);
  return columnWithTypeProperty.data?.table?.rows.every((row: any) => row[1] === SeeqNames.Types.Asset);
};

const columnValuesCanBeUsedForPassThruCreation = async (
  tableDefinitionId: string,
  column: ScalingTableColumnDefinition,
): Promise<boolean> => {
  if (column.columnType !== ColumnTypeEnum.UUID) {
    return false;
  }
  const columnWithTypeProperty = await getMaterializedTable(
    tableDefinitionId,
    [column.columnName],
    [{ uuidColumn: column.columnName, propertyNames: ['type'] }],
  );
  return columnWithTypeProperty.data?.table?.rows.every(
    (row: any) => row[1] !== SeeqNames.Types.Asset && row[1] !== SeeqNames.Types.TableDefinition,
  );
};

//todo: change this to search processMatTableFrom store because it is text columns
export const columnIsTextWithOnlyOneValueOrNull = async (
  tableDefinitionId: string,
  column: ScalingTableColumnDefinition,
): Promise<boolean> => {
  if (column.columnType !== ColumnTypeEnum.TEXT) {
    return false;
  }
  const columnValues = await getMaterializedTable(tableDefinitionId, [column.columnName]);
  const distinctValues = _.uniq(columnValues.data?.table?.rows.map((row: any) => row[0]));
  console.log('distinctValues', distinctValues);
  return distinctValues.filter((v) => !!v).length <= 1;
};
