// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import classNames from 'classnames';
import _ from 'lodash';
import { Select } from '@seeqdev/qomponents';

import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface SelectOption<T> {
  label: string;
  value: T;
}

export interface SelectIF extends ValidatingFormComponent<string | string[]> {
  component: 'SelectFormComponent';
  value: string | string[];
  options: SelectOption<any>[];
  placeholder?: string;
  required?: boolean;
  popoverContent?: React.ReactNode;
  insideModal?: boolean;
  disabled?: boolean;
  searchable?: boolean;
  creatable?: boolean;
  isMulti?: boolean;
  onTooltipClick?: () => void;
}

/**
 * Native select form component with optional tool-tip/popover
 */
export const SelectFormComponent: React.FunctionComponent<SelectIF> = (props) => {
  const {
    name,
    testId = 'select',
    label,
    options,
    validation,
    extendValidation,
    extraClassNames,
    wrapperClassNames,
    customErrorText,
    placeholder,
    required,
    popoverContent,
    value,
    insideModal = false,
    disabled = false,
    searchable = false,
    creatable = false,
    isMulti = false,
    tooltip,
    onTooltipClick,
  } = props;

  const defaultValidation = (value) => required && !value;

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const findSelectedLabel = () => _.find(options, (option) => option.value === value)?.label;

  const { t } = useTranslation();
  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;
  const [selectedLabel, setSelectedLabel] = useState(findSelectedLabel());

  useEffect(() => {
    setSelectedLabel(findSelectedLabel() || selectedLabel);
  }, [options, value]);

  const getOptionLabel = (option: SelectOption<any>) => `${t(option.label)}`;

  const getOptionValue = (option: SelectOption<any>) => option.value;

  const handleChange = (onChangeFn) => (selected: SelectOption<any>) => onChangeFn(selected.value);
  const handleChangeIfMulti = (onChangeFn) => (selected) => onChangeFn(_.map(selected, (each) => each.value));

  return (
    <FormFieldWrapper
      testId={testId}
      label={label}
      wrapperClassNames={classNames(wrapperClassNames, 'flexFillOverflow')}
      extraClassNames={extraClassNames}
      showError={showError}
      customErrorText={customErrorText}
      popoverContent={popoverContent}
      tooltip={tooltip}
      onTooltipClick={onTooltipClick}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const formFieldProps = getFormFieldProps(formState, input, meta, props);
          const getValueIfMulti = () =>
            formFieldProps.value ? _.map(formFieldProps.value, (each) => ({ label: each, value: each })) : undefined;
          const getValue = () =>
            formFieldProps.value ? { value: formFieldProps.value, label: selectedLabel || '' } : undefined;

          return (
            <Select
              showError={formFieldProps.showError}
              placeholder={t(placeholder)}
              value={isMulti ? getValueIfMulti() : getValue()}
              options={options}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              onChange={isMulti ? handleChangeIfMulti(formFieldProps.onChange) : handleChange(formFieldProps.onChange)}
              creatable={creatable}
              isMulti={isMulti}
              isSearchable={searchable}
              menuPortalTarget={document.body}
              isDisabled={disabled}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
